"use client";

// import Image from 'next/image'
import Link from "next/link";
import { UserInfo } from "@prisma/client";
import { Tag } from "lucide-react";
import { toast } from "sonner";

import { Button } from "@/components/ui/button";

import { CreatorBadge } from "./creator-bagde";
import { StatBadge } from "./stat-badge";

// import { Button } from '@/components/ui/button'
// import { toast } from 'sonner'
interface CharacterCardProps {
  id: string;
  name: string;
  imageUrl: string;
  fireCount: string;
  starCount: string;
  description: string;
  supportsAudio: boolean;
  author?: UserInfo;
  tags?: string[];
  blur?: boolean;
}

export function CharacterCard({
  id,
  name,
  imageUrl,
  fireCount,
  starCount,
  description,
  supportsAudio,
  author,
  tags,
  blur,
}: CharacterCardProps) {
  // const handleDelete = (evt) => {
  //   evt.preventDefault()
  //   evt.stopPropagation()
  //   fetch(`/api/characters/${id}`, {
  //     method: 'DELETE',
  //   })
  //   .then(() => {
  //     toast.success('删除成功')
  //   })
  //   .catch(() => {
  //     toast.error('删除失败')
  //   })
  // }

  const handleBlur = (evt) => {
    evt.preventDefault();
    evt.stopPropagation();
    fetch(`/api/characters/${id}`, {
      method: "PATCH",
      body: JSON.stringify({
        isImageBlur: true,
      }),
    })
      .then(() => {
        toast.success("置顶成功");
      })
      .catch(() => {
        toast.error("置顶失败");
      });
  };

  // const handleUpdate = (evt) => {
  //   evt.preventDefault()
  //   evt.stopPropagation()
  //   fetch(`/api/characters/${id}`, {
  //     method: 'PUT',
  //     body: JSON.stringify({
  //       updatedAt: new Date().toISOString(),
  //       // realName: name
  //     })
  //   })
  //   .then(() => {
  //     toast.success('置顶成功')
  //   })
  //   .catch(() => {
  //     toast.error('置顶失败')
  //   })
  // }
  return (
    <Link
      href={`/character/${id}`}
      className="relative flex w-full flex-col justify-between overflow-hidden rounded-xl bg-cover bg-center bg-no-repeat p-2 transition-transform duration-300 ease-in-out hover:scale-[0.97] hover:cursor-pointer xs:h-[260px] sm:h-[520px] lg:h-[440px]"
    >
      <img
        src={imageUrl}
        alt="Avatar"
        // fill
        // priority
        className="absolute left-0 top-0 z-0 h-full w-full object-cover"
        style={
          blur
            ? {
                filter: "blur(10px)",
              }
            : {}
        }
      />
      <div
        className="absolute bottom-0 left-0 right-0 top-0 rounded-xl"
        style={{
          background:
            "linear-gradient(rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 100%)",
        }}
      />
      <div className="z-[1] flex justify-between">
        <div className="flex items-center space-x-1 text-white">
          {(tags || []).map((tag) => (
            <StatBadge key={tag} icon={<Tag size={12} />} value={tag} />
          ))}

          {/* <Button onClick={handleBlur} size={'sm'} variant={'secondary'}>
            Blur
          </Button> */}
          {/* <Button onClick={handleDelete} size={'sm'} variant={'secondary'}>
            删除
          </Button>
          <Button onClick={handleUpdate} size={'sm'} variant={'blue'}>
            置顶
          </Button> */}
          {/* <StatBadge 
            icon={
              <Tag/>
            }
            value={fireCount}
          />
          <div className="ml-[6px]">
            <StatBadge 
              icon={
                <svg stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" aria-hidden="true" className="h-3 w-3" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M11.049 2.927c.3-.921 1.603-.921 1.902 0l1.519 4.674a1 1 0 00.95.69h4.915c.969 0 1.371 1.24.588 1.81l-3.976 2.888a1 1 0 00-.363 1.118l1.518 4.674c.3.922-.755 1.688-1.538 1.118l-3.976-2.888a1 1 0 00-1.176 0l-3.976 2.888c-.783.57-1.838-.197-1.538-1.118l1.518-4.674a1 1 0 00-.363-1.118l-3.976-2.888c-.784-.57-.38-1.81.588-1.81h4.914a1 1 0 00.951-.69l1.519-4.674z"></path>
                </svg>
              }
              value={starCount}
            />
          </div> */}
        </div>
        {author ? (
          <>
            <div className="items-center sm:hidden lg:flex">
              <CreatorBadge author={author} />
            </div>
            <div className="items-center sm:flex lg:hidden">
              <CreatorBadge author={author} />
            </div>
          </>
        ) : null}
      </div>
      <div className="z-[1]">
        <div className="flex items-center justify-start gap-2">
          <span className="text-xl font-bold text-white">{name}</span>
        </div>
        <div className="mt-2 flex max-h-[248px] min-h-[61px] items-center rounded-bl-[20px] rounded-br-[20px] rounded-tr-[20px] border border-pink-500 bg-black/10 px-3 py-2 text-[13px] font-medium text-white backdrop-blur-[12px]">
          <span className="hover:line-clamp-12 line-clamp-2">
            {description}
          </span>
        </div>
        <div className="mt-1 items-center sm:flex lg:hidden" />
      </div>
    </Link>
  );
}
