// empty

export const CustomEmpty = (props: { text?: string; size?: number }) => {
  return (
    <div className="flex items-center justify-center py-6">
      <div>
        <img
          src="/_static/images/empty.png"
          alt="empty"
          className="block h-16 w-auto"
          style={{
            width: props.size || "auto",
          }}
        />
        {props.text && (
          <p className="mt-4 text-center text-gray-400">{props.text}</p>
        )}
      </div>
    </div>
  );
};
