import(/* webpackMode: "eager", webpackExports: ["CharacterCard"] */ "/vercel/path0/components/character/ui/character-card.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/character/user-list/infinit-list.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["FilterSelects"] */ "/vercel/path0/components/list-filter/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ProfileGuide"] */ "/vercel/path0/components/profile-guide/index.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/ui/LoadingDots/LoadingDots.module.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@15.0.3_@babel+core@7.24.5_@opentelemetry+api@1.9.0_react-dom@18.3.1_react@18.3.1__react@18.3.1_sass@1.81.0/node_modules/next/dist/client/link.js");
