import { getSmartImageUrl } from "@/utils/helpers";
import { UserInfo } from "@prisma/client";
// import Image from "next/image";

interface CreatorBadgeProps {
  author?: UserInfo
  // name: string;
  // avatarUrl: string;
}

export function CreatorBadge(props: CreatorBadgeProps) {
  return props.author ? (
    <div className="bg-op-20 flex rounded-full bg-black px-1 pl-0">
      <img
        src={getSmartImageUrl(props.author.avatar || "" )}
        alt="creator avatar"
        width={16}
        height={16}
        className="border-1 h-4 w-4 rounded-full border-white"
      />
      <p className="ml-[4.8px] mr-[4.8px] text-[10px] font-medium text-white">
        {props.author.nickname}
      </p>
    </div>
  ) : null;
}
