"use client";

import { useCallback, useEffect, useState } from "react";
import { useRouter, useSearchParams } from "next/navigation";

import { ActionResponse } from "@/types/action-response";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";

import { Input } from "../ui/input";
import { MobileSearch } from "../mobile-search";

export function FilterSelects(props: {
  getTags: () => Promise<ActionResponse>;
}) {
  const router = useRouter();
  const searchParams = useSearchParams();

  const [tags, setTags] = useState<string[]>([]);
  //   useEffect(() => {
  //     props.getTags().then(() => {
  //       setTags(tags);
  //     });
  //   }, []);
  const createQueryString = useCallback(
    (name: string, value: string) => {
      const params = new URLSearchParams(searchParams);
      params.set(name, value);
      return params.toString();
    },
    [searchParams],
  );

  const updateUrl = (name: string, value: string) => {
    router.push(`?${createQueryString(name, value)}`);
  };

  return (
    <div className="flex items-center justify-between space-x-4">
      {/* <Select
        value={searchParams.get("label") ?? ""}
        onValueChange={(value) => updateUrl("label", value)}
      >
        <SelectTrigger className="w-[180px]">
          <SelectValue placeholder="角色标签" />
        </SelectTrigger>
        <SelectContent>
          {tags.map((tag) => (
            <SelectItem key={tag} value={tag}>
              {tag}
            </SelectItem>
          ))}
        </SelectContent>
      </Select> */}
      <Select
        value={searchParams.get("gender") ?? "FEMALE"}
        onValueChange={(value) => updateUrl("gender", value)}
      >
        <SelectTrigger className="w-[180px]">
          <SelectValue placeholder="角色性别" />
        </SelectTrigger>
        <SelectContent>
          <SelectItem value="MALE">男</SelectItem>
          <SelectItem value="FEMALE">女</SelectItem>
          <SelectItem value="OTHER">非二元</SelectItem>
        </SelectContent>
      </Select>
      {/* <Input
        type="text"
        placeholder="搜索角色"
        className=""
        value={searchParams.get("search") ?? ""}
        onChange={(e) => updateUrl("search", e.target.value)}
      /> */}

      <MobileSearch onChange={(value) => updateUrl("search", value)} value={
        searchParams.get("search") ?? ""
      } />

      {/* search input */}
    </div>
  );
}
