"use client";

import { useCallback, useState } from "react";
import { Search, X } from "lucide-react";

import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";

export function MobileSearch(props: {
  onChange: (value: string) => void;
  value?: string;
}) {
  const [searchTerm, setSearchTerm] = useState(props.value || "");

  const handleSubmit = useCallback(
    (e: React.FormEvent) => {
      e.preventDefault();
      props.onChange(searchTerm);
    },
    [searchTerm, props.onChange],
  );

  const handleClear = () => {
    setSearchTerm("");
    props.onChange("");
  };

  return (
    <form onSubmit={handleSubmit} className="mx-auto w-full max-w-sm">
      <div className="relative flex items-center">
        <Search className="absolute left-3 top-1/2 h-5 w-5 -translate-y-1/2 transform text-gray-400" />
        <Input
          type="search"
          placeholder="搜索..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="w-full rounded-full border-gray-300 py-2 pl-10 pr-10 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
         //   add enter
         onKeyDown={e => {
            if (e.key === 'Enter') {
                handleSubmit(e);
            }
         }}

        />
        {searchTerm && (
          <Button
            type="button"
            variant="ghost"
            size="sm"
            className="absolute right-16 top-1/2 -translate-y-1/2 transform rounded-m"
            onClick={handleClear}
          >
            <X className="h-4 w-4 text-white-400" />
            <span className="sr-only">清除搜索</span>
          </Button>
        )}
        <Button
          size="sm"
          className="absolute right-1 top-1/2 -translate-y-1/2 transform rounded-full"
          onClick={handleSubmit}
        >
          搜索
        </Button>
      </div>
    </form>
  );
}
