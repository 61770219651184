import { getSmartImageUrl } from "@/utils/helpers";

import { CharMeta } from "@/types/chat";
import { ImagePlaceholder } from "@/lib/placeholder";

import { CharacterCard } from "../ui/character-card";
import { CharGridLayout } from "../ui/grid";

type Props = {
  items: CharMeta[];
};

export const UserCharacterList = (props: Props) => {
  return (
    <>
      <CharGridLayout>
        {props.items.map((item) => {
          return (
            <CharacterCard
              key={item.id}
              id={item.id}
              name={item.displayName || item.realName}
              imageUrl={getSmartImageUrl(
                item.galleryImages[0] || item.avatar || ImagePlaceholder,
              )}
              blur={item.isImageBlur}
              fireCount={100 + ""}
              starCount={100 + ""}
              author={item.author}
              description={item.shortDescription || item.note ||  ""}
              supportsAudio={false}
              tags={item.tags ? item.tags.slice(0, 3) : []}
            ></CharacterCard>
          );
        })}
      </CharGridLayout>
    </>
  );
};
