"use client";

import { useCallback, useEffect, useRef, useState } from "react";
import { ChatCharacter } from "@prisma/client";
import { nanoid } from "nanoid";
import { useInView } from "react-intersection-observer";

import { CustomEmpty } from "@/components/ui/empty";
import LoadingDots from "@/components/ui/LoadingDots";

import { UserCharacterList } from ".";
import { CharMeta } from "@/types/chat";

export default function InfiniteList(props: {
  initOffset?: number;
  size?: number;
  query?: {
    search?: string;
    gender?: string;
  }
}) {
  const [itemGroups, setItemGroups] = useState<
    {
      uuid: string;
      items: CharMeta[];
    }[]
  >([]);
  //   const [page, setPage] = useState(1)
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(false);

  const { ref, inView } = useInView({
    threshold: 0,
  });

  const fetchItems = useCallback(async () => {
    if (loading || !hasMore) return;
    setLoading(true);
    let count = 0;
    itemGroups.forEach((itemGroup) => {
      count += itemGroup.items.length;
    });
    const offset = props.initOffset ? props.initOffset + count : count;
    const size = props.size || 24;
    try {
      const query: Record<string, any>  = props.query || {};
      query.offset = offset;
      query.size = size;
      const queryStr = Object.keys(query).map(key => key + '=' + query[key]).join('&');
      const response = await fetch(
        `/api/characters?${queryStr}`,
      );
      const data = await response.json();

      const items = data?.data?.items || [];
      if (items.length === 0) {
        setHasMore(false);
        return;
      }
      setItemGroups([
        ...itemGroups,
        {
          uuid: nanoid(),
          items,
        },
      ]);
      setHasMore(true);
      //   setPage(prevPage => prevPage + 1)
    } catch (error) {
      console.error("Error fetching items:", error);
    } finally {
      setLoading(false);
    }
  }, [itemGroups, loading, props.initOffset]);

  useEffect(() => {
    if (inView) {
      fetchItems();
    }
  }, [inView, fetchItems]);

  return (
    <>
      {itemGroups.map((group) => {
        return (
          <div
            key={group.uuid}
            className="columns-1 gap-4 sm:columns-2 md:columns-3 lg:columns-4"
          >
            <UserCharacterList items={group.items} />
          </div>
        );
      })}

      {loading && (
        <div className="mt-2 flex h-8 items-center justify-center rounded-md bg-white bg-opacity-20">
          <LoadingDots />
        </div>
      )}
      {!hasMore && (
        <p className="my-4 text-center">
          <CustomEmpty text="已经被掏空了" />
        </p>
      )}
      <div ref={ref} className="h-10" />
    </>
  );
}
