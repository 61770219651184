"use client";

import { useCallback, useEffect, useState } from "react";
import Link from "next/link";
import { useRouter } from "next/navigation";
import { UserInfo } from "@prisma/client";
import { User } from "@supabase/supabase-js";
import { Check, Edit2, Loader2, Save, Send } from "lucide-react";
import { toast } from "sonner";

import { getUserNickname } from "@/lib/user";
import { UserProfileUpdateSchema } from "@/lib/validations/character";
import { Button } from "@/components/ui/button";
import { Card } from "@/components/ui/card";

import { Input } from "../ui/input";

export function ProfileGuide(props: {
  user?: User & {
    appUserProfile?: UserInfo;
  };
}) {
  const [show, setShow] = useState(false);

  const router = useRouter();

  useEffect(() => {
    if (!props.user) {
      setShow(false);
      return;
    }
    const sessionNickname = window.sessionStorage.getItem("nickname");
    if (sessionNickname) {
      setShow(false);
      return;
    }
    if (props.user?.appUserProfile?.nickname) {
      setShow(false);
    } else {
      setShow(true);
    }
  }, [props.user]);
  const [value, setValue] = useState(
    props.user ? getUserNickname(props.user) : "",
  );
  const [loading, setLoading] = useState(false);
  const submit = useCallback(async () => {
    setLoading(true);
    const validRes = await UserProfileUpdateSchema.safeParse({
      nickname: value,
    });
    if (!validRes.success) {
      setLoading(false);
      toast.error(validRes.error.message);
      return;
    }
    try {
      const res = await fetch(`/api/profile/${props.user!.id}/settings`, {
        method: "PUT",
        body: JSON.stringify({
          nickname: value,
        }),
      });
      toast.success("昵称设置成功");
      setShow(false);
      window.sessionStorage.setItem("nickname", value);
      window.location.reload();
    } catch (e) {
      toast.error("昵称设置失败");
    } finally {
      setLoading(false);
    }
  }, [value]);

  return show ? (
    <div
      className="fixed left-0 top-0 z-50 flex items-center justify-center p-4"
      style={{
        width: "100vw",
        height: "100vh",
        background: `rgba(0, 0, 0, 0.82)`,
      }}
    >
      <Card className="w-96 border-gray-800 bg-gray-900/50 p-8">
        <div className="space-y-6">
          <h3 className="text-md mb-4 text-lg font-bold text-yellow-400">
            唔...你是个神秘人物
          </h3>
          <div>
            要不要先给自己起个响亮的名字呢？这样的话角色扮演体验会更好哦～
          </div>
          <div>
            <Input
              value={value}
              placeholder="请输入昵称..."
              onChange={(e) => setValue(e.target.value)}
            />
          </div>
          <div className="flex justify-between">
            <Button
              variant="ghost"
              size="sm"
              className=" "
              onClick={() => {
                setShow(false);
              }}
            >
              下次一定 😂
            </Button>
            <Button variant="primary" size="sm" className="" onClick={submit}>
              {loading && <Loader2 className="animate-spin" size={14} />}
              起好了 👌
              {/* <Check className="ml-2" size={12} /> */}
            </Button>
          </div>
        </div>
      </Card>
    </div>
  ) : null;
}
