import { ReactNode } from 'react'

interface StatBadgeProps {
  icon: ReactNode
  value: string
}

export function StatBadge({ icon, value }: StatBadgeProps) {
  
  return (
    <div className="flex items-center rounded-full  px-1" style={{
      background: `#9C27B0`
    }}>
      {icon}
      <p className="text-[12px] ml-1">{value}</p>
    </div>
  )
}

