import React from 'react'

interface GridLayoutProps {
  children: React.ReactNode
}

export function CharGridLayout({ children }: GridLayoutProps) {
  return (
    <div className="grid grid-cols-2 sm:grid-cols-[repeat(auto-fill,minmax(160px,1fr))] lg:grid-cols-[repeat(auto-fill,minmax(251px,1fr))] gap-4">
    {children}
  </div>
  )
}

